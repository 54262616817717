import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faHistory, faHomeAlt } from '@fortawesome/pro-regular-svg-icons';
import { sortBy } from 'lodash-es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoaderFunctionArgs, Outlet, useParams } from 'react-router-dom';
import invariant from 'tiny-invariant';
import { orderConfirmationIcon } from '../../../../ui/icons/business-objects';
import { PageHeader, PageTab } from '../../../../ui/page';
import Page from '../../../../ui/page/page';
import usePlugins, { PluginToken } from '../../../../util/plugin/use-plugins';
import useFetcher from '../../../../util/swr/use-fetcher';
import usePermission from '../../../permission/use-permission';
import CollectiveOrderConfirmationEditButton from '../collective-order-confirmation-editor/collective-order-confirmation-edit-button';
import CollectiveOrderConfirmationMenu from '../collective-order-confirmation-menu/collective-order-confirmation-menu';
import { fetchCollectiveOrderConfirmation } from '../collective-order-confirmation-queries';

export interface CollectiveOrderConfirmationTabItem {
  order: number;
  label: React.ReactNode;
  icon: IconProp;
  to: string;
}

export const COLLECTIVE_ORDER_CONFIRMATION_TAB_ITEM = new PluginToken<CollectiveOrderConfirmationTabItem>(
  'OrderConfirmationTabItem',
);
export const Component = CollectiveOrderConfirmationPageRoute;

export const loader = ({ params }: LoaderFunctionArgs) => {
  return fetchCollectiveOrderConfirmation.mutate({ id: String(params.collectiveOrderConfirmationId) });
};

function CollectiveOrderConfirmationPageRoute() {
  const { collectiveOrderConfirmationId } = useParams<{ collectiveOrderConfirmationId: string }>();

  invariant(collectiveOrderConfirmationId, 'Empty collectiveOrderConfirmationId');
  const collective = useFetcher(fetchCollectiveOrderConfirmation, { id: collectiveOrderConfirmationId });
  const tabs = <CollectiveOrderConfirmationPageTabs />;
  const { hasPermission } = usePermission();

  return (
    <Page>
      <PageHeader
        title={collective.collectiveOrderConfirmationNumber}
        tabs={tabs}
        actions={
          <ButtonGroup>
            <Box>
              <CollectiveOrderConfirmationMenu collectiveOrderConfirmation={collective} />
            </Box>
            {hasPermission('COLLECTIVE_ORDER_CONFIRMATION:CAN_EDIT') && (
              <CollectiveOrderConfirmationEditButton collectiveOrderConfirmation={collective} buttonType={Button} />
            )}
          </ButtonGroup>
        }
      />
      <Outlet />
    </Page>
  );
}

function CollectiveOrderConfirmationPageTabs() {
  const { t } = useTranslation(['common', 'order_confirmation']);
  const tabItems = usePlugins(COLLECTIVE_ORDER_CONFIRMATION_TAB_ITEM);
  const sortedTabItems = React.useMemo(() => sortBy(tabItems, 'order'), [tabItems]);

  return (
    <>
      <PageTab to="." icon={faHomeAlt} />
      <PageTab to="./order-confirmations" icon={orderConfirmationIcon}>
        {t('order_confirmation:orderConfirmations')}
      </PageTab>
      {sortedTabItems.map((tabItem, index) => (
        <PageTab key={'additionalTab' + index} to={tabItem.to} icon={tabItem.icon}>
          {tabItem.label}
        </PageTab>
      ))}
      <PageTab to="./history" icon={faHistory}>
        {t('common:history.label')}
      </PageTab>
    </>
  );
}
