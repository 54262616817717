import React from 'react';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';

const driveUpListRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'drive-up-list',
    handle: {
      breadcrumb: <Translate ns="drive_up_list" i18nKey="driveUpList" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./drive-up-list-page/drive-up-list-page-route'),
        children: [
          {
            path: '',
            lazy: () => import('./drive-up-list-viewer/drive-up-list-viewer-route'),
          },
          {
            path: 'settings',
            lazy: () => import('./drive-up-list-settings-viewer/drive-up-list-settings-viewer-route'),
          },
        ],
      },
      {
        path: 'new',
        lazy: () => import('./drive-up-list-settings-editor/new-drive-up-list-settings-route'),
      },
      {
        path: 'edit',
        lazy: () => import('./drive-up-list-settings-editor/edit-drive-up-list-settings-route'),
      },
      {
        path: 'entry',
        children: [
          {
            path: 'new',
            lazy: () => import('./drive-up-list-entry-editor/new-drive-up-list-entry-route'),
          },
        ],
      },
    ],
  },
];

export default driveUpListRoutes;
