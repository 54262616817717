import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Outlet } from 'react-router-dom';
import App from './app';
import { logoutMenuItems, mainMenuItems } from './app-menu-items';
import accessGroupRoutes from './feature/access-group/access-group-routes';
import accountRoutes from './feature/account/account-routes';
import admissionRoutesMobile from './feature/admission/admission-routes-mobile';
import AppLayout from './feature/app/app-layout/app-layout';
import AppMobileLayout from './feature/app/app-layout/app-mobile-layout';
import AppPrintLayout from './feature/app/app-layout/app-print-layout';
import AppPublicLayout from './feature/app/app-layout/app-public-layout';
import Login from './feature/app/auth/login/login';
import Logout from './feature/app/auth/logout';
import HelpPage from './feature/app/help/help-page';
import SwitchMobile from './feature/app/switch-mobile/switch-mobile';
import awardRoutes from './feature/award/award-routes';
import codeRoutes from './feature/code/code-routes';
import companyRoutes from './feature/company/company-routes';
import countryRoutes from './feature/country/country-routes';
import drivingAssignmentRoutes from './feature/driving-assignment/driving-assignment-routes';
import drivingEditionSettingsRoutes from './feature/driving-edition-settings/driving-edition-settings-routes';
import editionRoutes from './feature/edition/edition-routes';
import eventRoutes, { eventRoutesMobile } from './feature/event/event-routes';
import exportRoutes from './feature/export/export-routes';
import filmRoutes from './feature/film/film-routes';
import formatRoutes from './feature/format/format-routes';
import groupTemplateRoutes from './feature/group-template/group-template-routes';
import groupRoutes from './feature/group/group-routes';
import invoiceRoutes from './feature/invoice/invoice-routes';
import landingPageDesignRoutes from './feature/landing-page-design/landing-page-design-routes';
import landingPageTemplateRoutes from './feature/landing-page-template/landing-page-template-routes';
import landingPageRoutes from './feature/landing-page/landing-page-routes';
import listRoutes from './feature/list/list-routes';
import locationRoutes from './feature/location/location-routes';
import mailingBlocklistRoutes from './feature/mailing-blocklist/mailing-blocklist-routes';
import mailingDesignRoutes from './feature/mailing-design/mailing-design-routes';
import mailingListRoutes from './feature/mailing-list/mailing-list-routes';
import mailingTemplateRoutes from './feature/mailing-template/mailing-template-routes';
import mailingRoutes from './feature/mailing/mailing-routes';
import myAccountRoutes from './feature/my-account/my-account-routes';
import offerRoutes from './feature/offer/offer-routes';
import orderConfirmationRoutes from './feature/order-confirmation/order-confirmation-routes';
import orderRoutes from './feature/order/order-routes';
import participationRoutes from './feature/participation/participation-routes';
import paymentRoutes from './feature/payment-transaction/payment/payment-routes';
import permissionClassGroupRoutes from './feature/permission-class-group/permission-class-group-routes';
import { PermissionContextProvider } from './feature/permission/use-permission';
import personRoutes from './feature/person/person-routes';
import { placementPrintRoutes } from './feature/placement/placement/placement-routes';
import productBundleRoutes from './feature/product-bundle/product-bundle-routes';
import productRoutes from './feature/product/product-routes';
import publicEventEvaluationRoutes from './feature/public/event-evaluation/public-event-evaluation-routes';
import publicKeyPlayersListRoutes from './feature/public/key-player-list/public-key-player-list-routes';
import publicLiveTicketingRoutes from './feature/public/live-ticketing/public-live-ticketing-routes';
import publicRundownRoutes from './feature/public/rundown/public-rundown-routes';
import reportingRoutes from './feature/reporting/reporting-routes';
import sectionRoutes from './feature/section/section-routes';
import { AccountProvider } from './feature/staff-account/use-account/use-staff-account';
import staffRoutes from './feature/staff/staff-routes';
import standRoutes from './feature/stand/stand-routes';
import systemMailingRoutes from './feature/system-mailing/system-mailing-routes';
import tagRoutes from './feature/tag/tag-routes';
import teamRoutes from './feature/team/team-routes';
import vehicleBookingRoutes from './feature/vehicle-booking/vehicle-booking-routes';
import visaTemplateRoutes from './feature/visa-template/visa-template-routes';
import visaRoutes from './feature/visa/visa-routes';
import { BreadcrumbRouteObject } from './ui/breadcrumbs/breadcrumbs';
import NotFound from './ui/error-pages/not-found';
import RouteErrorPage from './ui/error-pages/route-error-page';
import HelmetOutlet, { HelmetRouteObject } from './ui/helmet/helmet-outlet';
import Translate from './util/translate/translate';
import vehicleRoutes from './feature/vehicle/vehicle-routes';

const LandingPage = React.lazy(() => import('./feature/app/landing-page/landing-page'));

const mobileRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'mobile',
    element: (
      <AccountProvider>
        <PermissionContextProvider>
          <AppMobileLayout>
            <Outlet />
          </AppMobileLayout>
        </PermissionContextProvider>
      </AccountProvider>
    ),
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: '',
        element: (
          <>
            <HelmetOutlet />
            <Outlet />
          </>
        ),
        errorElement: <RouteErrorPage />,
        children: [
          ...admissionRoutesMobile,
          ...eventRoutesMobile,
          {
            path: '',
            element: <Navigate to="/mobile/events" />,
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
        handle: {
          helmet: <Translate ns="app">{(t) => <Helmet title={t('pageNotFound')} />}</Translate>,
        },
      },
    ],
  },
];

const publicationRoutes: HelmetRouteObject[] = [
  {
    path: 'public',
    element: (
      <PermissionContextProvider>
        <AccountProvider>
          <AppPublicLayout>
            <Outlet />
          </AppPublicLayout>
        </AccountProvider>
      </PermissionContextProvider>
    ),
    handle: {
      helmet: (
        <Translate ns="app">
          {(t) => <Helmet defaultTitle={t('project_title')} titleTemplate={`%s – ${t('project_title')}`} />}
        </Translate>
      ),
    },
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: '',
        element: (
          <>
            <HelmetOutlet />
            <Outlet />
          </>
        ),
        errorElement: <RouteErrorPage />,
        children: [
          ...publicKeyPlayersListRoutes,
          ...publicRundownRoutes,
          ...publicLiveTicketingRoutes,
          ...publicEventEvaluationRoutes,
        ],
      },
      {
        path: '*',
        element: <NotFound />,
        handle: {
          helmet: <Translate ns="app">{(t) => <Helmet title={t('pageNotFound')} />}</Translate>,
        },
      },
    ],
  },
];

const printRoutes: HelmetRouteObject[] = [
  {
    path: 'print',
    element: (
      <PermissionContextProvider>
        <AccountProvider>
          <AppPrintLayout>
            <Outlet />
          </AppPrintLayout>
        </AccountProvider>
      </PermissionContextProvider>
    ),
    handle: {
      helmet: (
        <Translate ns="app">
          {(t) => <Helmet defaultTitle={t('project_title')} titleTemplate={`%s – ${t('project_title')}`} />}
        </Translate>
      ),
    },
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: '',
        element: (
          <>
            <HelmetOutlet />
            <Outlet />
          </>
        ),
        errorElement: <RouteErrorPage />,
        children: [...placementPrintRoutes],
      },
      {
        path: '*',
        element: <NotFound />,
        handle: {
          helmet: <Translate ns="app">{(t) => <Helmet title={t('pageNotFound')} />}</Translate>,
        },
      },
    ],
  },
];

const routes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    element: <App />,
    children: [
      {
        children: [
          {
            path: '',
            element: (
              <PermissionContextProvider>
                <AccountProvider>
                  <AppLayout mainMenuItems={mainMenuItems} accountMenuItems={logoutMenuItems}>
                    <Outlet />
                  </AppLayout>
                </AccountProvider>
              </PermissionContextProvider>
            ),
            errorElement: <RouteErrorPage />,
            handle: {
              helmet: (
                <Translate ns="app">
                  {(t) => <Helmet defaultTitle={t('project_title')} titleTemplate={`%s – ${t('project_title')}`} />}
                </Translate>
              ),
            },
            children: [
              {
                path: '',
                element: (
                  <>
                    <HelmetOutlet />
                    <Outlet />
                  </>
                ),
                errorElement: <RouteErrorPage />,
                handle: {
                  breadcrumb: <Translate ns="app" i18nKey="home" />,
                },
                children: [
                  ...accessGroupRoutes,
                  ...accountRoutes,
                  ...awardRoutes,
                  ...codeRoutes,
                  ...companyRoutes,
                  ...countryRoutes,
                  ...drivingAssignmentRoutes,
                  ...drivingEditionSettingsRoutes,
                  ...editionRoutes,
                  ...eventRoutes,
                  ...exportRoutes,
                  ...filmRoutes,
                  ...formatRoutes,
                  ...groupRoutes,
                  ...groupTemplateRoutes,
                  ...invoiceRoutes,
                  ...landingPageRoutes,
                  ...landingPageDesignRoutes,
                  ...landingPageTemplateRoutes,
                  ...listRoutes,
                  ...locationRoutes,
                  ...mailingBlocklistRoutes,
                  ...mailingDesignRoutes,
                  ...mailingListRoutes,
                  ...mailingRoutes,
                  ...mailingTemplateRoutes,
                  ...myAccountRoutes,
                  ...offerRoutes,
                  ...orderRoutes,
                  ...orderConfirmationRoutes,
                  ...participationRoutes,
                  ...paymentRoutes,
                  ...permissionClassGroupRoutes,
                  ...personRoutes,
                  ...productBundleRoutes,
                  ...productRoutes,
                  ...reportingRoutes,
                  ...sectionRoutes,
                  ...staffRoutes,
                  ...standRoutes,
                  ...systemMailingRoutes,
                  ...tagRoutes,
                  ...teamRoutes,
                  ...vehicleRoutes,
                  ...vehicleBookingRoutes,
                  ...visaTemplateRoutes,
                  ...visaRoutes,
                  {
                    path: '',
                    element: (
                      <>
                        <LandingPage />
                        <SwitchMobile to="/mobile/events" />
                      </>
                    ),
                    handle: {
                      // reset old title from login
                      helmet: <Helmet title="" />,
                    },
                  },
                  {
                    path: 'help',
                    element: <HelpPage />,
                    handle: {
                      helmet: <Translate ns="app">{(t) => <Helmet title={t('help.label')} />}</Translate>,
                      breadcrumb: <Translate ns="app" i18nKey="help.label" />,
                    },
                  },
                ],
              },
              {
                path: '*',
                element: <NotFound />,
                handle: {
                  helmet: <Translate ns="app">{(t) => <Helmet title={t('pageNotFound')} />}</Translate>,
                },
              },
            ],
          },
        ],
      },
      {
        path: 'login',
        element: (
          <>
            <HelmetOutlet />
            <Login />
          </>
        ),
        handle: {
          helmet: <Translate ns="app">{(t) => <Helmet title={t('login.title')} />}</Translate>,
        },
      },
      {
        path: 'logout',
        element: <Logout />,
      },
      ...mobileRoutes,
      ...publicationRoutes,
      ...printRoutes,
    ],
  },
];

export default routes;
