import { faCars } from '@fortawesome/pro-regular-svg-icons';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import EVENT_CHILD_ROUTES from '../event/event-child-routes';
import { EVENT_MENU_ITEM, EventMenuItem } from '../event/event-page/event-page-menu';
import driveUpListRoutes from './drive-up-list-routes';

export const driveUpListEventMenuItem: EventMenuItem = {
  order: 6,
  label: <Translate ns="drive_up_list" i18nKey="driveUpList" />,
  icon: faCars,
  path: (eventId) => `/events/${eventId}/drive-up-list`,
};

export default function DriveUpListPluginProvider(registry: PluginRegistry) {
  registry.register(EVENT_MENU_ITEM, driveUpListEventMenuItem);
  registry.register(EVENT_CHILD_ROUTES, driveUpListRoutes);
}
