/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EditionReferenceDto } from './EditionReferenceDto';
import {
    EditionReferenceDtoFromJSON,
    EditionReferenceDtoFromJSONTyped,
    EditionReferenceDtoToJSON,
} from './EditionReferenceDto';
import type { VersionDto } from './VersionDto';
import {
    VersionDtoFromJSON,
    VersionDtoFromJSONTyped,
    VersionDtoToJSON,
} from './VersionDto';

/**
 * Vehicle for the transportation of guests or film teams
 * @export
 * @interface VehicleListItemDto
 */
export interface VehicleListItemDto {
    /**
     * The unique identifier for an entity.
     * @type {string}
     * @memberof VehicleListItemDto
     */
    id: string;
    /**
     * 
     * @type {EditionReferenceDto}
     * @memberof VehicleListItemDto
     */
    edition: EditionReferenceDto;
    /**
     * 
     * @type {string}
     * @memberof VehicleListItemDto
     */
    vehicleNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleListItemDto
     */
    licensePlate: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleListItemDto
     */
    vehicleModel: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleListItemDto
     */
    engine: string;
    /**
     * 
     * @type {string}
     * @memberof VehicleListItemDto
     */
    vehicleType: string;
    /**
     * 
     * @type {number}
     * @memberof VehicleListItemDto
     */
    seat: number;
    /**
     * 
     * @type {VersionDto}
     * @memberof VehicleListItemDto
     */
    version: VersionDto;
}

/**
 * Check if a given object implements the VehicleListItemDto interface.
 */
export function instanceOfVehicleListItemDto(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('edition' in value)) return false;
    if (!('licensePlate' in value)) return false;
    if (!('vehicleModel' in value)) return false;
    if (!('engine' in value)) return false;
    if (!('vehicleType' in value)) return false;
    if (!('seat' in value)) return false;
    if (!('version' in value)) return false;
    return true;
}

export function VehicleListItemDtoFromJSON(json: any): VehicleListItemDto {
    return VehicleListItemDtoFromJSONTyped(json, false);
}

export function VehicleListItemDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): VehicleListItemDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'edition': EditionReferenceDtoFromJSON(json['edition']),
        'vehicleNumber': json['vehicleNumber'] == null ? undefined : json['vehicleNumber'],
        'licensePlate': json['licensePlate'],
        'vehicleModel': json['vehicleModel'],
        'engine': json['engine'],
        'vehicleType': json['vehicleType'],
        'seat': json['seat'],
        'version': VersionDtoFromJSON(json['version']),
    };
}

export function VehicleListItemDtoToJSON(value?: VehicleListItemDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'edition': EditionReferenceDtoToJSON(value['edition']),
        'vehicleNumber': value['vehicleNumber'],
        'licensePlate': value['licensePlate'],
        'vehicleModel': value['vehicleModel'],
        'engine': value['engine'],
        'vehicleType': value['vehicleType'],
        'seat': value['seat'],
        'version': VersionDtoToJSON(value['version']),
    };
}

