/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  ImportErrorDto,
  ImportSuccessDto,
  PageableDto,
  StringPageDto,
  VehicleDto,
  VehiclePageDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    ImportErrorDtoFromJSON,
    ImportErrorDtoToJSON,
    ImportSuccessDtoFromJSON,
    ImportSuccessDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
    StringPageDtoFromJSON,
    StringPageDtoToJSON,
    VehicleDtoFromJSON,
    VehicleDtoToJSON,
    VehiclePageDtoFromJSON,
    VehiclePageDtoToJSON,
} from '../models/index';

export interface CreateVehicleRequest {
    vehicleDto: VehicleDto;
}

export interface DeleteVehicleByIdRequest {
    id: string;
}

export interface FetchVehicleRequest {
    id: string;
}

export interface FetchVehicleTypesRequest {
    q?: string;
    pageable?: PageableDto;
}

export interface ImportVehiclesRequest {
    file: Blob;
}

export interface SearchVehicleListItemsRequest {
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface UpdateVehicleRequest {
    id: string;
    vehicleDto: VehicleDto;
}

/**
 * 
 */
export class VehicleApi extends runtime.BaseAPI {

    /**
     * Create a new vehicle.
     */
    async createVehicleRaw(requestParameters: CreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['vehicleDto'] == null) {
            throw new runtime.RequiredError(
                'vehicleDto',
                'Required parameter "vehicleDto" was null or undefined when calling createVehicle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDtoToJSON(requestParameters['vehicleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new vehicle.
     */
    async createVehicle(requestParameters: CreateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createVehicleRaw(requestParameters, initOverrides);
    }

    /**
     * Delete vehicle by id
     */
    async deleteVehicleByIdRaw(requestParameters: DeleteVehicleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteVehicleById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete vehicle by id
     */
    async deleteVehicleById(requestParameters: DeleteVehicleByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteVehicleByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the vehicle business object.
     */
    async fetchVehicleRaw(requestParameters: FetchVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling fetchVehicle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDtoFromJSON(jsonValue));
    }

    /**
     * Get the vehicle business object.
     */
    async fetchVehicle(requestParameters: FetchVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDto> {
        const response = await this.fetchVehicleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the vehicle types of the active edition.
     */
    async fetchVehicleTypesRaw(requestParameters: FetchVehicleTypesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StringPageDto>> {
        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StringPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the vehicle types of the active edition.
     */
    async fetchVehicleTypes(requestParameters: FetchVehicleTypesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StringPageDto> {
        const response = await this.fetchVehicleTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Import vehicles of the CSV
     */
    async importVehiclesRaw(requestParameters: ImportVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImportSuccessDto>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling importVehicles().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/vehicles/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportSuccessDtoFromJSON(jsonValue));
    }

    /**
     * Import vehicles of the CSV
     */
    async importVehicles(requestParameters: ImportVehiclesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImportSuccessDto> {
        const response = await this.importVehiclesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for vehicles.
     */
    async searchVehicleListItemsRaw(requestParameters: SearchVehicleListItemsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehiclePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehiclePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for vehicles.
     */
    async searchVehicleListItems(requestParameters: SearchVehicleListItemsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehiclePageDto> {
        const response = await this.searchVehicleListItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetch the template for creating a new vehicle.
     */
    async templateVehicleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VehicleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/template`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VehicleDtoFromJSON(jsonValue));
    }

    /**
     * Fetch the template for creating a new vehicle.
     */
    async templateVehicle(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VehicleDto> {
        const response = await this.templateVehicleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the existing vehicle.
     */
    async updateVehicleRaw(requestParameters: UpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateVehicle().'
            );
        }

        if (requestParameters['vehicleDto'] == null) {
            throw new runtime.RequiredError(
                'vehicleDto',
                'Required parameter "vehicleDto" was null or undefined when calling updateVehicle().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/vehicles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VehicleDtoToJSON(requestParameters['vehicleDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing vehicle.
     */
    async updateVehicle(requestParameters: UpdateVehicleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateVehicleRaw(requestParameters, initOverrides);
    }

}
